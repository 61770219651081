const staticBannerHomepage4i = function(){	
	const init = () => {
		const newsletterloader = new newsletterLoader();
		newsletterloader.Init();
	};

    	return {
    		Init: init
    	};
};

(async () => {
	const homepage = new staticBannerHomepage4i();
	homepage.Init();
})();
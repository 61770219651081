const newsletterLoader = function() {
        let options, observer,
        divNewsletter = document.getElementById('divNewsletter'),
        init = () => {
            observer = new IntersectionObserver(callBack, options);
            if (divNewsletter)
                setUpObserve(divNewsletter);
        },
        setUpObserve = container => {
            options = { root: container, threshold: 0.9 }; // 0.9 instead of 1 for safari fix
            observer.observe(container);
        },
        callBack = (entries, observer) => {
            entries.forEach(function (entry) {
                if (entry.intersectionRatio > 0) {
                    if (entry.target === divNewsletter) {
                        getNewsletterSection();
                        observer.unobserve(divNewsletter);
                    }
                }
            });
        },
        getNewsletterSection = function () {
            var loading = '<div id="newsletterLoading" class="spinner" role="alert" aria-atomic="true" aria-label="Loading, please wait"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
            divNewsletter.innerHTML = loading;
            fetch('/home/newsletter', {
                method: 'GET',
                headers: { 'X-Requested-With': 'fetch' }
            }).then(function (response) {
                return (response.ok) ? response.text() : []; //
            }).then(function (response) {
                divNewsletter.innerHTML = response;
                var head = document.getElementsByTagName('head')[0];
		   script = document.createElement('script');
		   script.src = document.getElementById('newsletterPath').value;
		   script.id = 'newsletter23js'
		   script.type = 'text/javascript';
		   script.async = true;
		   script.setAttribute('crossorigin', 'anonymous');
		   head.appendChild(script);
            }).catch(function (error) {
                divNewsletter.innerHTML = '';
            });
        };

        return {
            Init: init
        };

};